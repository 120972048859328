<template>
  <!-- View header -->
  <b-card
    no-body
    class="p-lg-2 px-1"
  >
    <b-row class="align-items-center justify-content-between">
      <b-col>
        <slot name="view-header" />
      </b-col>
      <b-col
        sm="1"
        class="d-flex justify-content-end d-lg-none"
      >
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none">
          <feather-icon
            icon="AlignRightIcon"
            size="21"
            class="cursor-pointer"
            @click="EventBus.$emit('show-tournament-sidebar', true)"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
  <!-- /View header -->
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import EventBus from '@/common/event-bus'

export default {
  components: {
    BCard,
    BCol,
    BRow,
  },
  computed: {
    EventBus() {
      return EventBus
    },
  },

}
</script>

<style scoped lang="scss">

</style>
