var render = function () {
  var _vm$tournament, _vm$tournament2, _vm$tournament3, _vm$tournament3$ranki, _vm$tournament3$ranki2, _vm$tournament4, _vm$tournament4$ranki, _vm$tournament5, _vm$tournament6, _vm$tournament6$ranki, _vm$tournament6$ranki2, _vm$tournament7, _vm$tournament7$ranki, _vm$tournament8, _vm$tournament9, _vm$tournament9$ranki, _vm$tournament9$ranki2, _vm$tournament10, _vm$tournament10$rank, _vm$tournament11, _vm$tournament12, _vm$tournament13, _vm$tournament14, _vm$tournament15;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-view-container"
  }, [_c('tournament-view-header', {
    scopedSlots: _vm._u([{
      key: "view-header",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "m-0"
        }, [_vm._v(" Tournament info & rules ")])];
      },
      proxy: true
    }])
  }), _c('b-row', [_c('b-col', [((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.status_id) === _vm.TournamentStatusType.FINISHED || ((_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : _vm$tournament2.status_id) === _vm.TournamentStatusType.CLOSED ? _c('b-card', {
    staticStyle: {
      "background-color": "#28304547"
    }
  }, [_c('h5', {
    staticClass: "m-0 mb-2"
  }, [_vm._v(" Final results ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-card', [_c('span', [_c('b-img', {
    attrs: {
      "src": require("@/assets/images/icons/1stplace.png"),
      "alt": "1st place",
      "height": "35"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bold ml-2 font-medium-2"
  }, [_vm._v(_vm._s(((_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : (_vm$tournament3$ranki = _vm$tournament3.rankings[0].participant) === null || _vm$tournament3$ranki === void 0 ? void 0 : (_vm$tournament3$ranki2 = _vm$tournament3$ranki.participant) === null || _vm$tournament3$ranki2 === void 0 ? void 0 : _vm$tournament3$ranki2.name) || ((_vm$tournament4 = _vm.tournament) === null || _vm$tournament4 === void 0 ? void 0 : (_vm$tournament4$ranki = _vm$tournament4.rankings[0].participant) === null || _vm$tournament4$ranki === void 0 ? void 0 : _vm$tournament4$ranki.name)))])])], 1), ((_vm$tournament5 = _vm.tournament) === null || _vm$tournament5 === void 0 ? void 0 : _vm$tournament5.rankings.length) >= 2 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-card', [_c('span', [_c('b-img', {
    attrs: {
      "src": require("@/assets/images/icons/2place.png"),
      "alt": "2nd place",
      "height": "35"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bold ml-2 font-medium-2"
  }, [_vm._v(" " + _vm._s(((_vm$tournament6 = _vm.tournament) === null || _vm$tournament6 === void 0 ? void 0 : (_vm$tournament6$ranki = _vm$tournament6.rankings[1].participant) === null || _vm$tournament6$ranki === void 0 ? void 0 : (_vm$tournament6$ranki2 = _vm$tournament6$ranki.participant) === null || _vm$tournament6$ranki2 === void 0 ? void 0 : _vm$tournament6$ranki2.name) || ((_vm$tournament7 = _vm.tournament) === null || _vm$tournament7 === void 0 ? void 0 : (_vm$tournament7$ranki = _vm$tournament7.rankings[1].participant) === null || _vm$tournament7$ranki === void 0 ? void 0 : _vm$tournament7$ranki.name)) + " ")])])], 1) : _vm._e(), ((_vm$tournament8 = _vm.tournament) === null || _vm$tournament8 === void 0 ? void 0 : _vm$tournament8.rankings.length) >= 3 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-card', [_c('span', [_c('b-img', {
    attrs: {
      "src": require("@/assets/images/icons/3place.png"),
      "alt": "3rd place",
      "height": "35"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bold ml-2 font-medium-2"
  }, [_vm._v(" " + _vm._s(((_vm$tournament9 = _vm.tournament) === null || _vm$tournament9 === void 0 ? void 0 : (_vm$tournament9$ranki = _vm$tournament9.rankings[2].participant) === null || _vm$tournament9$ranki === void 0 ? void 0 : (_vm$tournament9$ranki2 = _vm$tournament9$ranki.participant) === null || _vm$tournament9$ranki2 === void 0 ? void 0 : _vm$tournament9$ranki2.name) || ((_vm$tournament10 = _vm.tournament) === null || _vm$tournament10 === void 0 ? void 0 : (_vm$tournament10$rank = _vm$tournament10.rankings[2].participant) === null || _vm$tournament10$rank === void 0 ? void 0 : _vm$tournament10$rank.name)) + " ")])])], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _vm.isLoading ? _c('b-row', {
    staticClass: "text-center p-2"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('b-row', {
    class: ((_vm$tournament11 = _vm.tournament) === null || _vm$tournament11 === void 0 ? void 0 : _vm$tournament11.status_id) >= _vm.TournamentStatusType.FINISHED ? 'scroll-container final-results' : 'scroll-container'
  }, [_c('b-col', {
    staticClass: "h-100"
  }, [_c('b-tabs', {
    staticClass: "h-100",
    attrs: {
      "content-class": "mt-1 h-100",
      "pills": ""
    }
  }, [_c('b-tab', {
    staticStyle: {
      "height": "calc(100% - 40px)"
    },
    attrs: {
      "title": "Information"
    }
  }, [_c('b-card', {
    staticClass: "h-100",
    attrs: {
      "body-class": "h-100"
    }
  }, [_c('b-row', {
    staticClass: "h-100"
  }, [_c('b-col', {
    staticClass: "h-100"
  }, [_c('vue-perfect-scrollbar', {
    staticClass: "h-100",
    attrs: {
      "settings": {
        suppressScrollX: true
      }
    }
  }, [!((_vm$tournament12 = _vm.tournament) !== null && _vm$tournament12 !== void 0 && _vm$tournament12.info) ? _c('p', [_vm._v(" No information provided by the organizer. ")]) : _c('p', {
    domProps: {
      "innerHTML": _vm._s((_vm$tournament13 = _vm.tournament) === null || _vm$tournament13 === void 0 ? void 0 : _vm$tournament13.info)
    }
  })])], 1)], 1)], 1)], 1), _c('b-tab', {
    staticStyle: {
      "height": "calc(100% - 50px)"
    },
    attrs: {
      "lazy": "",
      "title": "Rules"
    }
  }, [_c('b-card', {
    staticClass: "h-100 x"
  }, [_c('b-row', {
    staticClass: "row h-100"
  }, [_c('b-col', {
    staticClass: "h-100"
  }, [_c('vue-perfect-scrollbar', {
    staticClass: "h-100",
    attrs: {
      "settings": {
        suppressScrollX: true
      }
    }
  }, [!((_vm$tournament14 = _vm.tournament) !== null && _vm$tournament14 !== void 0 && _vm$tournament14.rules) ? _c('p', [_vm._v(" No rules provided by the organizer. ")]) : _c('p', {
    domProps: {
      "innerHTML": _vm._s((_vm$tournament15 = _vm.tournament) === null || _vm$tournament15 === void 0 ? void 0 : _vm$tournament15.rules)
    }
  })])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }