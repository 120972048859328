var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "p-lg-2 px-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', [_vm._t("view-header")], 2), _c('b-col', {
    staticClass: "d-flex justify-content-end d-lg-none",
    attrs: {
      "sm": "1"
    }
  }, [_c('div', {
    staticClass: "sidebar-toggle d-block d-lg-none"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "AlignRightIcon",
      "size": "21"
    },
    on: {
      "click": function click($event) {
        return _vm.EventBus.$emit('show-tournament-sidebar', true);
      }
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }